import './App.css';
import { useEffect } from 'react';
import { Duration } from 'luxon';
import { useState } from 'react';
import TimerClient from './TimerClient';

interface Timer{
  version: number,
  time: number
  id: string
  duration: number
  name: string
  state: TimerState,
  type: TimerType
}

enum TimerState{
  STOPPED = "STOPPED",
  PAUSED = "PAUSED",
  RUNNING = "RUNNING",
}

enum TimerType{
  TIMER = 0,
  COUNTDOWN = 1
}

let timeOffset = 0;

function App() {

  let [timerText, setTimerText] = useState("--:--");

  useEffect(() => {
    
    let client = new TimerClient();

    let published: Timer | null = null;

    client.on('ready', () => {
        published = client.get(client.publishedTimerID);
    });

    client.on("time_synced", (currentTime: number) => {
      timeOffset = currentTime - Date.now();
    });

    client.on('timer_published', (id: number) => {
        published = client.get(client.publishedTimerID);
    });

    client.on('timer_updated', (timer: Timer) => {
        if(published == null) return;
        if(published.id == timer.id){
            published = timer;
        }
    });

    client.on('timer_deleted', (id) => {
        if(published == null) return;
        if(published.id == id){
            published = null;
        }
    });

    client.connect();

    setInterval(() => {
      if(published == null){
        setTimerText("--:--");
        return;
      }
      setTimerText(getTimerText(published));
    }, 50);

  }, []);

  return (
    <div className={"h-screen w-screen bg-emerald"}>
      <div className={"flex flex-col justify-center items-center h-screen w-screen bg-gradient-to-r from-black/20 via-black/40 to-black/20"}>
        <p className={"text-white text-4xl p-0"} >The show will begin in</p>
        <h1 className={"text-white text-mega font-bold"}>{timerText}</h1>
      </div>
    </div>
  );
}

function msToCountdownText(millis: number){
  let dur = Duration.fromMillis(millis);
  let output = dur.toFormat("dd:hh:mm:ss")
  return output.replace(/^[0:]+(?=\d[\d:]{3})/, '');
}

function getTimerText(timer: Timer){
  if(timer == null) return "--:--";
  let timeLeft;
  switch(timer.state){
      case "RUNNING":
          timeLeft = timer.time - (Date.now() + timeOffset);
      break;
      case "STOPPED":
          timeLeft = timer.duration;
      break;
      case "PAUSED":
          timeLeft = timer.time;
      break;
      default:
          return "--:--";
  }
  timeLeft = Math.max(timeLeft, 0);
  return msToCountdownText(timeLeft);
}


export default App;
